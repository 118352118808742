

























import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ManageCourierController'

@Component({})
export default class CancelValidation extends Vue {
  controller = controller

  tabs = [
    {
      label: 'Daftar Cuti/Libur',
      name: 'ManageCourierLeaveTabList',
    },
    {
      label: 'Daftar Pengajuan Cuti/Libur',
      name: 'ManageCourierLeaveTabApprovalList',
    },
  ]

  created(): void {
    if (this.$route.name !== 'ManageCourierLeaveTabApprovalList') {
      this.controller.getCourierLeaveTotalRequest()
    }
  }
}
